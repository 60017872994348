/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #707070;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    margin: 0;
  }
  a {
    color: #707070;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    float: left;
    margin: 0 10px 30px 0;
  }
}

.accordion.accordion-custom {
  padding: 10px 10px 5px 10px;
  border-top: 1px dotted #ccc;

  .accord-content {
    display: none;
    margin-top: 10px;
  }

  &:last-child {
    border-bottom: 1px dotted #ccc;
  }

  h2,
  h3 {
    margin-left: 20px;
    padding-right: 20px;
    position: relative;
    cursor: pointer;

    &:before {
      color: $theme-primary;
      content: '\e842';
      display: inline-block;
      font-family: 'icons';
      font-size: 12px;
      font-weight: normal !important;
      height: inherit;
      margin-left: -18px;
      vertical-align: middle;
      width: 18px;
      position: relative;
      top: -1px;
    }

    &.active {
      &:before {
        content: '\e843';
      }
    }

    &.noaccord {
      margin-left: 0;
      padding-right: 0;
      cursor: text;

      &:before,
      &:after {
        content: '';
      }
    }
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    img {
      margin-right: 5px;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      font-size: 1.5em;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-family: 'Fjalla One', 'Open Sans', Verdana;
    }
    span.small {
      font-size: 18px;
    }
  }
  #support {
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.help-center {
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
  }
}

.bg-light-trans {
  background: rgba(255, 255, 255, 0.75) !important;
}
.font-weight-strong {
  font-weight: 600 !important;
}
.border-red {
  border-color: $theme-primary;
}
.bg-red {
  background: $theme-primary;
}
.box-shadow {
  box-shadow:
    rgba(0, 0, 0, 0.12) 0 1px 5px,
    rgba(0, 0, 0, 0.24) 0 1px 4px;
}

#header-wrap {
  position: relative;
  background: rgba(255, 255, 255, 0.85);
}

#header-wrapper {
  background: #fff url(../images/header-bg.jpg) no-repeat;
  background-size: cover;
}

#banner {
  .price {
    &.circle {
      margin: 0 auto 20px;
      background: #ebe7e4;
      border-radius: 150px;
      width: 130px;
      height: 130px;
      border: 1px solid #bd0000;
    }
    .only {
      font-size: 14px;
      text-shadow: 0 0 0;
      font-weight: 400;
      margin-bottom: 8px;
      &:after {
        content: '';
        display: block;
        margin: 0 auto;
        width: 45%;
        border-bottom: 3px solid #bd0000;
      }
    }
  }
  .select-course {
    width: 100%;
    max-width: 320px;
    z-index: 1;
    .dropdown {
      font-family: 'Fjalla One', Impact, Verdana;
      text-transform: uppercase;
      color: #fff;
      font-size: 21px;
      border: 0;
      height: 50px;
      line-height: 2.4em;
      &:hover {
        background: darken($cta-green, 5%);
      }
    }
    .drop {
      top: 50px;
    }
  }
  .cta-btn {
    box-shadow: 0 0 5px #0000008c;
    text-shadow: 0 1px 3px #0000004c;
  }
  .sfs-icon {
    max-width: fit-content;
  }
}
#promo-wrap {
  background: #45555e;

  a {
    color: #cbccc6;
  }
}
#ansi-wrap {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  background: #45555e;
}
#other-courses {
  background: #f7f5f3;
  border-bottom: 1px solid #ebebeb;
}
#steps-wrap {
  background: #ebe7e4;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  .icon {
    h3 {
      position: relative;
      &:before {
        content: '';
        background: transparent url(../images/steps-icon.png) no-repeat 0 0 / cover;
        vertical-align: middle;
        width: 120px;
        height: 120px;
        margin: 0 auto;
        display: block;
      }
      &.step-2:before {
        background-position: -120px 0;
      }
      &.step-3:before {
        background-position: -240px 0;
      }
    }
  }
  .number div {
    p {
      padding-left: 60px;
    }
    &:before {
      display: inline-block;
      margin: 0 20px 0 0;
      width: 40px;
      height: 40px;
      content: '';
      background: transparent url(../images/numbers-icon.png) no-repeat 0 0 / cover;
      vertical-align: middle;
      transition: opacity 0.25s ease-out 0.25s;
      transition-delay: 0.1s;
      float: left;
    }
    &.step-2:before {
      background-position: -40px 0;
    }
    &.step-3:before {
      background-position: -80px 0;
    }
  }
}
.dropdown-list span.dropdown.green {
  background: $cta-green;
}
#footer-cta {
  background-color: #ebe7e4;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  .select-course {
    width: 100%;
    max-width: 320px;
    z-index: 1;
    .dropdown {
      font-family: 'Fjalla One', Impact, Verdana;
      text-transform: uppercase;
      color: #fff;
      font-size: 21px;
      border: 0;
      height: 50px;
      line-height: 2.4em;
      &:hover {
        background: darken($cta-green, 5%);
      }
    }
    .drop {
      top: 50px;
    }
  }
  .dropdown-list .drop {
    &.drop-top {
      bottom: 50px;
      top: auto;
      border-top: 1px solid #a1c4e5 !important;
      border-bottom: 0;
    }
  }
}
.card {
  &.business {
    max-width: 650px;
  }
  &.red-top {
    border-top: 3px solid #bd0000;
  }
  &.middle {
    margin-top: -15px;
  }
  .best-value {
    background: $theme-primary;
    width: 100%;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.75);
  }
}
.icon-chat,
.icon-phone {
  height: 35px;
  width: 35px;
  font-size: 1.4em;
  color: #ccc;
  line-height: 30px;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      font-size: 1.9em;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 55%;
  }
  #header-wrap {
    border-top: 1px solid $theme-primary;
  }
  #steps-wrap .number div {
    &:before {
      width: 80px;
      height: 80px;
    }
    &.step-2:before {
      background-position: -80px 0;
    }
    &.step-3:before {
      background-position: -160px 0;
    }
  }
  .border-lg-0 {
    border: 0 !important;
  }
}
